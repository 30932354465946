import React, {useState, useEffect, useRef} from 'react'
import { Header, Form, Modal, StepGroup}  from 'semantic-ui-react'
import {useApi, usePageSelect} from  '@wollo-lib/kpe-context'
import {showDatum, useCheckForm,DatePicker, useCheckFormArray} from '@wollo-lib/wollo-react'
import useTexts from '../../../lib20/useTexts'
import { useConfig } from '../../../lib20/KPE20context'
import JobTransfer from './JobTransfer'

const Timestamp=(props)=>
{
    const {children,setModalParams, resetModal, modalParams, modalSubmit,modalDefaults}=props
    const {UID,group,text,textDate,category, type}=modalParams[0]
    const  {apiUpdate, apiSelect, apiInsert, apiDelete}=useApi()
    const texts=useTexts('/edit/person')
    const [timestamp,setTimestamp]=useState({timestamp:null})
    const [minLinkDate,setMinLinkDate]=useState(null)
    const [myGroup,setMyGroup]=useState()
    const [successor, setSuccessor] = useState([''])
    const config=useConfig()


    const [personFunctions, setPersonFunctions] = useState([])
    const jsonFields=JSON.stringify([
        {path:'$.firstName', alias:'firstName'},
        {path:'$.lastName', alias: 'lastName'}
    ])
    useEffect(()=>{
        const getJobs=async ()=>
        {
            const result=await apiSelect(`/kpe20/person/jobs/${UID}?Data=${jsonFields}&timestamp=${timestamp}`)
            if(result.success)
            {
                setPersonFunctions(result.result)
            }
        }
        getJobs()
    },[UID])


    // useEffect(()=>{
    //     setSuccessor(Array(personFunctions.length).fill(""))
    // }, [personFunctions])


    const {errors:timeErrors,checkSubmit:checkTimeSubmit}=useCheckForm(timestamp, [
        {name: 'timestamp', error: texts.errorTimestamp ,test:(val)=>{
            return val!==undefined && val!=='' && val!==0 && val!==null
        }},
        {name: 'timestamp', error: texts.errorTimestampLinkMin + 
            showDatum(minLinkDate,{year:"numeric",month:"2-digit", day:"2-digit",locale:config.locale, milliseconds:true}) ,test:(val)=>{
        return !val || !minLinkDate || val>=minLinkDate 
        }, display:'always'},
        {name: 'timestamp', error: texts.errorTimestampMax ,test:(val)=>{
            return !val || val<=new Date().setUTCHours(23,59,59,9999).valueOf()
        }, display:'always'}
    ])   
    const {errors: getSuccessorError, checkSubmit: checkSuccessorSubmit} = useCheckFormArray(successor,
    [
        {name:successor, error: texts.errorSuccessor, test:(val,index)=>(
            val!=='')},
        {name:successor, error: texts.errorSelectOtherPerson, test:(val,index)=>(
            val===UID)}
    ])
    
    useEffect(()=>{
        setMyGroup(group.UID)
    },[group])
     

    useEffect(()=>{
        const getPerson= async ()=>
        {
            const result=await apiSelect(`/kpe20/person/${UID}/?parent=1`)
            if(result.success)
            {
                if(type !=='extern')     // only retrict time, when the person is member (we cann not backdate the exit)
                {
                    
                        setMinLinkDate(result.result.parent.LvalidFrom*1000)
                }
                else
                {
                    // can not die before born
                    setMinLinkDate(result.result.Data.birthday*1000)
                }
            }
        }
        if(UID)
            getPerson()

    },[UID])


    modalSubmit.current=async (event)=> 
    {
        if(!checkTimeSubmit() || !checkSuccessorSubmit())
            return
        let queryString=''
        if(Date.now()-timestamp.timestamp >= 0)
            queryString='?timestamp='+timestamp.timestamp
        // give the person the former category
        const data={category:category}
        if(category.includes('died'))
            data.died= timestamp.timestamp/1000

        
        // Transfer of functions (UI in ./JobTransfer.jsx)
        personFunctions.forEach(async (func,index) => {
            if (successor[index]) {
                const res= await apiInsert(`/kpe20/job/${successor[index]}/${func.UIDgroup}/${func.UIDBelongsTo}`,{empty:true},'kpe20')
                if(!res.success)
                    window.alert(res.message)

                apiDelete('/kpe20/job/'+func.UID)
            }
        })

        await apiUpdate('/kpe20/person/'+UID,data)
        // move the person per group update to extern
        const result=await apiUpdate(`/kpe20/extern/${group.UID}/${UID}${queryString}`)
        if(result.success)
        {
            resetModal()
        }
        else
        {
            window.alert('error update extern: '+result.message)
        }
    }

    const now=new Date()
    return(<>
        <Modal.Header as='h3' content={text}/>
        <Modal.Content>
            <Form>
                <Form.Field
                    label= {textDate}
                    control={DatePicker}
                    jsTime
                    value={timestamp.timestamp}
                    onChange={(ev,{value})=>setTimestamp({timestamp:value})}
                    maxDate={new Date(now.getUTCFullYear(),now.getUTCMonth,now.getUTCDay,25,59,59)}
                    error={timeErrors.timestamp}
                />

                {personFunctions.length !== 0 &&
                    <JobTransfer
                        successor={successor} 
                        setSuccessor={setSuccessor} 
                        personFunctions={personFunctions} 
                        getError={getSuccessorError}
                    />
                }
            </Form>

        </Modal.Content>
        {children}
    </>)
}

export default Timestamp