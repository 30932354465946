import React, {useState, useEffect, useRef} from 'react'
import { Header, Form, Modal, Segment}  from 'semantic-ui-react'
import {useApi, usePageSelect} from  '@wollo-lib/kpe-context'
import {SearchSelect, showDatum, DatePicker} from '@wollo-lib/wollo-react'
import useTexts from '../../../lib20/useTexts'
import { useCheckForm } from '../../../Hooks/useCheckForm'
import { useConfig } from '../../../lib20/KPE20context'
import { useCheckFormArray } from '../../../Hooks/useCheckFormArray'
import JobTransfer from './JobTransfer'

const ChangeGroup=(props)=>
{
    const {children,setModalParams, resetModal, modalParams, modalSubmit,modalDefaults}=props
    const {UID,group,text,textDate, groupQuery,type}=modalParams[0]
    const { apiUpdate, apiSelect, apiInsert, apiDelete}=useApi()
    const [timestamp,setTimestamp]=useState({timestamp:null})
    const [minDate,setMinDate]=useState(null)
    const [minLinkDate,setMinLinkDate]=useState(null)
    const [successor, setSuccessor] = useState([''])
    const texts=useTexts('/edit/person')
    const config=useConfig()

    const [personFunctions, setPersonFunctions] = useState([])
    const jsonFields=JSON.stringify([
        {path:'$.firstName', alias:'firstName'},
        {path:'$.lastName', alias: 'lastName'}
    ])

    useEffect(()=>{
        const getJobs=async ()=>
        {
            const result=await apiSelect(`/kpe20/person/jobs/${UID}?Data=${jsonFields}&timestamp=${timestamp}`)
            if(result.success)
            {
                setPersonFunctions(result.result)
            }
        }
        getJobs()
    },[UID])


    // useEffect(()=>{
    //     setSuccessor(Array(personFunctions.length).fill(""))
    // }, [personFunctions])

    const [myGroup,setMyGroup]=useState({UID:null})

    const {errors:groupErrors,checkSubmit:checkGroupSubmit}=useCheckForm(myGroup,
    [
        {name: 'UID', error: texts.errorGroup ,test:(val)=>{
                return val!==undefined && val!==''
        }},
        

    ])
    const {errors:timeErrors,checkSubmit:checkTimeSubmit}=useCheckForm(timestamp, 
    [
        {name: 'timestamp', error: texts.errorTimestamp ,test:(val)=>{
            return val!==undefined && val!=='' && val!==0 && val!==null
        }},
        {name: 'timestamp', error: texts.errorTimestampMin + 
                showDatum(minDate,{year:"numeric",month:"2-digit", day:"2-digit",locale:config.locale, milliseconds:true}) ,test:(val)=>{
            return !val || !minDate ||  val>=minDate 
        }, display:'always'},
        {name: 'timestamp', error: texts.errorTimestampLinkMin + 
                showDatum(minLinkDate,{year:"numeric",month:"2-digit", day:"2-digit",locale:config.locale, milliseconds:true}) ,test:(val)=>{
                return !val || !minLinkDate || val>=minLinkDate 
        }, display:'always'},
        {name: 'timestamp', error: texts.errorTimestampMax ,test:(val)=>{
            return !val || val<=new Date().setUTCHours(23,59,59,9999).valueOf()
        }, display:'always'}
    ]) 
    const {errors: getSuccessorError, checkSubmit: checkSuccessorSubmit} = useCheckFormArray(successor,
    [
        {name:successor, error: texts.errorSuccessor, test:(val,index)=>(
            val!=='')},
        {name:successor, error: texts.errorSelectOtherPerson, test:(val,index)=>(
            val===UID)}
    ])
   

    useEffect(()=>{
        const getPerson= async ()=>
        {
            const result=await apiSelect(`/kpe20/person/${UID}/?parent=1`)
            if(result.success)
            {
                setMinLinkDate(result.result.parent.LvalidFrom*1000)
                setTimestamp({timestamp:Date.now()})
            }
        }
        if(UID)
            getPerson()

    },[UID])


    const changeGroup=async (ev,{value,result})=>
    {
        setMyGroup({UID:value})
        setMinDate(result.validFrom*1000)
        if(timestamp.timestamp<= (result.validFrom*1000))
        {
            setTimestamp({timestamp:result.validFrom*1000+1000})
            //window.alert(texts.alterTimestamp)
        }
    }
    


    modalSubmit.current=async (event)=> 
    {
        const error1=!checkGroupSubmit()
        const error2=!checkTimeSubmit()
        if(error1  ||  error2 )
            return
        let queryString=''
        if(Date.now()-timestamp.timestamp >= 24*3600)
            queryString='?timestamp='+timestamp.timestamp

        // Transfer of functions (UI in ./JobTransfer.jsx)
        personFunctions.forEach(async (func,index) => {
            if (successor[index]) {
                const res= await apiInsert(`/kpe20/job/${successor[index]}/${func.UIDgroup}/${func.UIDBelongsTo}`,{empty:true},'kpe20')
                if(!res.success)
                    window.alert(res.message)

                apiDelete('/kpe20/job/'+func.UID)
            }
        })

        const result=await apiUpdate(`/kpe20/${type}/${myGroup.UID}/${UID}${queryString}`)
        if(result.success)
        {
            resetModal()
        }
        else
        {
            window.alert('error update person: '+result.message)

        }
    }
    const now=new Date()
    return(<>
        <Modal.Header as='h3' content={text}/>
        <Modal.Content>
            <Form>
                <Form.Field
                    label= {textDate}
                    control={DatePicker}
                    jsTime
                    value={timestamp.timestamp}
                    onChange={(ev,{value})=>setTimestamp({timestamp:value})}
                    minDate= {minDate}
                    maxDate={new Date(now.getUTCFullYear(),now.getUTCMonth,now.getUTCDay,25,59,59)}
                    name='timestamp'
                    error={timeErrors.timestamp}
                    locale={config.locale}
                />
                <Form.Field
                    label = {texts.Group}
                    placeholder = {texts.Group}
                    control = {SearchSelect}
                    api = "kpe20"
                    SuggestUrl={'/kpe20/SearchData?types=group'+groupQuery}
                    Value2TitleUrl={'/kpe20/Value2Title?types=group'+groupQuery}
                    name = "UIDgroup"
                    value = {myGroup.UID}

                    autoSelect
                    onChange={changeGroup}
                    error={groupErrors.UID}
                />

                {personFunctions.length !== 0 &&
                    <JobTransfer 
                        successor={successor} 
                        setSuccessor={setSuccessor} 
                        personFunctions={personFunctions} 
                        getError={getSuccessorError}
                    />
                }
            </Form>

        </Modal.Content>
        {children}
    </>)
}

export default ChangeGroup