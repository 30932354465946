import {useApi, usePageSelect} from '@wollo-lib/kpe-context'
import {SearchSelect, useCheckFormArray} from '@wollo-lib/wollo-react'
import {useEffect, useState} from 'react'
import {Form, Header, Segment} from 'semantic-ui-react'
import useTexts from '../../../lib20/useTexts'


const JobTransfer = (props) => {

    const {successor, setSuccessor, personFunctions, getError} = props
    
    const texts=useTexts('/edit/person')

    const {apiSelect} = useApi()
    const [groups, setGroups] = useState([])


    // useEffect(() => {
    //     personFunctions.forEach(async (func,index) => {
    //         const myGroup= await apiSelect(`/kpe20/group/${func.UIDgroup}/?Timestamp=${timestamp}&parent=true&sibling=true`)
    //         if(myGroup.success) {
    //             setGroups((g,i) => (i === index ? myGroup.result : g))
    //         }
    //         else window.alert('alert')
    //     })
    //     // setSuccessor(Array(personFunctions.length).fill(""))
    // },[personFunctions])

    

    const changeSuccessor = (ev, {value, index}) => {
        if (successor.length < personFunctions.length) {
            let mySucc = Array(index + 1).fill('')
            mySucc = [...successor]
            mySucc[index] = value
            console.log(mySucc)
            setSuccessor(mySucc)
        }
        else{
            setSuccessor((s,i) => (i===index ? value : s))
        }
    }


    return(
        <Segment secondary>
            <Header as='h4' content={texts.functionsLeft} />

            {personFunctions.map((func, index) => (
                <Form.Field
                    label={func.TitleJob + texts.ofGroup + func.pGroup}
                    placeholder={texts.successor}
                    control={SearchSelect}
                    api='kpe20'
                    SuggestUrl={'/kpe20/SearchData?types=person'/*&gender='+groups[index]?.gender*//*+JSON.stringify(['person','guest','extern','job'])*/}
                    Value2TitleUrl='/kpe20/Value2Title'
                    name='successor'
                    index={index}
                    value={successor[index]}
                    onChange={changeSuccessor}
                    autoSelect
                    error={getError}
                    // required
                />
            ))}
        </Segment>
    )
}

export default JobTransfer